import { OptionsType } from 'destiny/dist/types/dropdown';

export type ProductInformationType = {
  title: string;
  details: {
    title: string;
    description: string;
  }[];
  icon: string;
};

export enum PRODUCT_TYPES_V2 {
  USD_BANKING = 'usd_banking',
  ON_OFF_RAMP = 'on_off_ramp',
  CRYPTO_PAYMENT_GATEWAY = 'crypto_payment_gateway',
  OTC_DESK = 'otc_desk',
}

export type ProductListItemType = { label: string; value: PRODUCT_TYPES_V2; description: string };

export type OnOffRampConfig = { currencies?: string[]; monthlyTradeVolumes?: OptionsType };
