import React, { ReactElement, useEffect, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import { captureException } from '@sentry/nextjs';
import { useLazyGetConfigurationQuery, useLazyGetNavigationBarConfigQuery } from 'api/config';
import { useLazyGetMerchantDetailsQuery } from 'api/home';
import { useGetUserQuery, useLazyGetUserPermissionQuery } from 'api/userApi';
import { ERROR_CODE, ERROR_TOKENS } from 'constants/index';
import mixpanelEvents from 'constants/mixpanel';
import { LOGIN_URLS, PUBLIC_ROUTES, ROUTE_KEYS, ROUTES_PATH } from 'constants/routeConfig';
import { KEYBOARD_SHORTCUT_KEYS } from 'constants/shortcuts';
import RouteGuard from 'hoc/RouteGuard';
import { useIsSuperUser, useKybStatus } from 'hooks';
import { useAppDispatch, useAppSelector } from 'hooks/toolkit';
import useKeyboardShortcuts from 'hooks/useKeyboardShortcuts';
import * as LDClient from 'launchdarkly-js-client-sdk';
import SessionExpired from 'modules/zero-state/SessionExpired';
import { useRouter } from 'next/router';
import { RootState } from 'store';
import { addShortcutListeners } from 'store/slices/shortcuts';
import {
  setConfiguration,
  setMerchantDetails,
  setNavigation,
  setPermissions,
  setRoles,
  setUserAccessFlags,
  setUserInfo,
} from 'store/slices/user';
import { setLDClientToWindow } from 'utils/launchDarkly';
import { LOCAL_STORAGE_KEYS, setToLocalStorage } from 'utils/localstorage';
import { trackMixpanel } from 'utils/mixpanel';
import ErrorPage from 'components/ErrorPage';
import { Loader } from 'components/loader/Loader';

interface AuthPropsType {
  children: ReactElement;
}

const getLoader = () => (
  <div className='tw-w-screen tw-h-screen tw-flex tw-items-center tw-justify-center'>
    <Loader width='64px' height='64px' />
  </div>
);

const AuthGuard: React.FC<AuthPropsType> = ({ children }) => {
  const [isLDReady, setIsLDReady] = useState(false);
  const router = useRouter();
  const isSuperUser = useIsSuperUser();
  const dispatch = useAppDispatch();
  const { isKybLoading, isKybStatusError } = useKybStatus();

  const { data: userData, isError: isB2bUserError, isLoading: isB2bUserLoading } = useGetUserQuery();

  useKeyboardShortcuts();

  const [
    getUserPermissions,
    {
      data: permissionsData,
      isError: isPermissionError,
      isLoading: isPermissionsLoading,
      isSuccess: isPermissionsSuccess,
      isUninitialized: isPermissionsUninitialized,
    },
  ] = useLazyGetUserPermissionQuery({});

  const [
    getConfiguration,
    {
      data: configuration,
      isError: isConfigurationError,
      isUninitialized: isConfigurationUninitialized,
      isLoading: isConfigurationLoading,
      isSuccess: isConfigurationSuccess,
    },
  ] = useLazyGetConfigurationQuery();
  const [
    getMerchantDetails,
    { data: merchantDetails, isLoading: isMerchantDetailsLoading, isUninitialized: isMerchantDetailsUnintialized },
  ] = useLazyGetMerchantDetailsQuery();
  const [
    getNavigationConfig,
    {
      data: navigationConfig,
      isLoading: isNavigationConfigLoading,
      isUninitialized: isNavigationConfigUninitialized,
      error: navigationConfigError,
      isSuccess: isNavigationConfigSuccess,
    },
  ] = useLazyGetNavigationBarConfigQuery();

  const { userSessionExpired, permissions } = useAppSelector((state: RootState) => state.user);

  const [showSessionExpired, setShowSessionExpired] = useState(false);

  const isPublicRoute = !!PUBLIC_ROUTES[router.pathname as keyof typeof PUBLIC_ROUTES];
  const isLoginRoute = !!LOGIN_URLS.includes(router.pathname as keyof typeof ROUTE_KEYS);

  const navigateToRoute = navigationConfig?.nav_items?.find(
    (item) => item.key === navigationConfig?.default_nav_key
  )?.route;

  const isLoading =
    isKybLoading ||
    isB2bUserLoading ||
    isMerchantDetailsLoading ||
    isConfigurationLoading ||
    isNavigationConfigLoading ||
    isPermissionsLoading ||
    (!!userData?.id &&
      (isMerchantDetailsUnintialized ||
        merchantDetails?.is_cash_ops_enabled ||
        isConfigurationUninitialized ||
        isNavigationConfigUninitialized ||
        isPermissionsUninitialized ||
        permissions === undefined));

  const redirectToLoginPage = isB2bUserError && !isPublicRoute;

  const redirectToPostLogin =
    userData?.id &&
    !isB2bUserError &&
    !userSessionExpired &&
    navigateToRoute &&
    (isLoginRoute || router.pathname === ROUTES_PATH.HOME);

  useEffect(() => {
    const fullName =
      userData?.first_name && userData?.last_name ? `${userData?.first_name} ${userData?.last_name}` : 'Loading';

    dispatch(setUserInfo({ ...userData, fullName }));

    if (userData?.id) {
      Sentry.setUser({ id: userData?.id });
      getMerchantDetails();
      getConfiguration();
      getNavigationConfig();
      getUserPermissions();
      setToLocalStorage(LOCAL_STORAGE_KEYS.XZAMP_USER, userData?.email);
    }

    dispatch(addShortcutListeners([KEYBOARD_SHORTCUT_KEYS.GOD_MODE]));

    console.log('AUTH_GUARD_USER_DATA_REFRESH_TEST');
  }, [userData]);

  useEffect(() => {
    if (isConfigurationSuccess && isLDReady) {
      dispatch(setConfiguration(configuration));
    }
  }, [configuration, isLDReady]);

  useEffect(() => {
    dispatch(setNavigation(navigationConfig));
  }, [navigationConfig]);

  useEffect(() => {
    if (!merchantDetails?.is_cash_ops_enabled && (isPermissionsSuccess || isPermissionError)) {
      dispatch(setPermissions(permissionsData?.permissions ?? null));
      dispatch(setRoles(permissionsData?.roles ?? null));

      if (!permissionsData?.permissions?.length) {
        captureException(new Error(ERROR_TOKENS.USER_WITH_NO_PERMISSIONS), {
          extra: {
            error: isPermissionError ? 'Error in fetching permissions' : 'No permissions found in response',
            userId: userData?.id,
          },
        });

        console.log(ERROR_TOKENS.USER_WITH_NO_PERMISSIONS);
      }
    }
  }, [merchantDetails?.is_cash_ops_enabled, permissionsData, isPermissionError, isPermissionsSuccess]);

  useEffect(() => {
    if (userSessionExpired && !isPublicRoute) setShowSessionExpired(true);
  }, [userSessionExpired]);

  useEffect(() => {
    if (!(process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' && !isSuperUser)) return;

    window?.addEventListener('contextmenu', (event) => {
      event.preventDefault();
    });
  }, []);

  useEffect(() => {
    if (!isMerchantDetailsLoading && merchantDetails?.id) {
      const {
        is_payouts_enabled,
        is_treasury_enabled,
        is_payments_enabled,
        is_business_banking_enabled,
        is_cash_ops_enabled,
      } = merchantDetails;

      if (is_cash_ops_enabled && process.env.NEXT_PUBLIC_CASH_OPS_HOST) {
        window.location.href = process.env.NEXT_PUBLIC_CASH_OPS_HOST;
      }

      dispatch(
        setUserAccessFlags({
          is_payouts_enabled,
          is_treasury_enabled,
          is_payments_enabled,
          is_business_banking_enabled,
        })
      );
      dispatch(setMerchantDetails(merchantDetails));
      initiateLaunchDarkly();
    }
  }, [merchantDetails]);

  useEffect(() => {
    if (userData?.id && merchantDetails?.id) {
      trackMixpanel(mixpanelEvents.SET_USER_PROPERTIES, {
        first_name: userData?.first_name,
        last_name: userData?.last_name,
        created_at: userData?.created_at,
        merchant_name: merchantDetails?.name,
      });
    }
  }, [merchantDetails, userData]);

  const initiateLaunchDarkly = () => {
    const clientSideID = process.env.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT;

    if (clientSideID) {
      // Set up the context properties. This context should appear on your
      // LaunchDarkly contexts dashboard soon after you run the demo.
      const context = {
        kind: 'user',
        key: userData?.id,
        userDomain: userData?.email?.split('@')[1],
        merchantId: merchantDetails?.id,
      };

      console.log('LAUNCH DARKLY REQUEST', context);
      const ldclient = LDClient.initialize(clientSideID, context);

      ldclient.on('failed', (err) => {
        console.error('LAUNCH DARKLY FAILED', err);
        captureException(new Error(ERROR_TOKENS.FAILED_TO_INITIATE_LAUNCHDARKLY), {
          extra: { err: err },
        });
        setIsLDReady(true);
      });
      ldclient.on('ready', () => {
        console.log('LAUNCH DARKLY READY');
        setLDClientToWindow(ldclient);
        setIsLDReady(true);
      });
    }
  };

  if (redirectToLoginPage) {
    router.push(ROUTES_PATH.LOGIN);
  }

  if (redirectToPostLogin) {
    router.push(navigateToRoute);
  }

  if (redirectToPostLogin || redirectToLoginPage || isLoading || (!isPublicRoute && !isLDReady)) return getLoader();

  if (showSessionExpired) return <SessionExpired />;

  if (
    isKybStatusError ||
    isConfigurationError ||
    isPermissionError ||
    (navigationConfigError && navigationConfigError?.status !== 401) ||
    (isNavigationConfigSuccess && !navigationConfig?.nav_items?.length)
  ) {
    return (
      <ErrorPage
        errorCode={ERROR_CODE.AUTH_LAYER_FAILURE}
        className='tw-h-screen'
        title='Something Went Wrong'
        description='Please reload the page and try again.'
      />
    );
  }

  return <RouteGuard>{children}</RouteGuard>;
};

export default AuthGuard;
