import { useState } from 'react';
import { useAddEntityMutation } from 'api/entities';
import { useLazyGetMerchantDetailsQuery } from 'api/home';
import { useGetProductsQuery } from 'api/prompt';
import { SESSION_CAPABILITY_CONTEXT_KEYS } from 'constants/index';
import { CheckBox } from 'destiny/dist/components/atoms/checkbox';
import { Button } from 'destiny/dist/components/molecules/button';
import { Label } from 'destiny/dist/components/molecules/label';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { Tooltip } from 'destiny/dist/components/molecules/tooltip/index';
import Input from 'destiny/dist/components/organisms/input';
import { defaultFn } from 'destiny/dist/constants';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { BUTTON_SIZE_TYPES, BUTTON_TYPES } from 'destiny/dist/constants/molecules/buttons';
import { TooltipPositions } from 'destiny/dist/constants/molecules/tooltip';
import { defaultFnType } from 'destiny/dist/types';
import { OptionsType } from 'destiny/dist/types/dropdown';
import { useSessionToken } from 'hooks/useSessionToken';
import { checkDependentProducts, getProductDetails } from 'modules/intent/intent.utils';
import { MapAny, PRODUCT_TYPES } from 'types';
import { sentenceCase } from 'utils/common';
import { trackMixpanel } from 'utils/mixpanel';
import CountryPicker from 'components/country-picker/CountryPicker';
import Popup from 'components/popup/Popup';

interface AddEntityPopupProps {
  isOpen: boolean;
  onClose: defaultFnType;
  refreshMerchant?: boolean;
}

interface StateType {
  name?: string;
  country?: OptionsType;
  intent?: string[];
}

const AddEntityPopup: React.FC<AddEntityPopupProps> = ({ isOpen, onClose, refreshMerchant = false }) => {
  const [state, setState] = useState<StateType>({ intent: [] });
  const [addEntity, { isLoading, error }] = useAddEntityMutation();
  const { data } = useGetProductsQuery();
  const { sessionToken, createSessionToken } = useSessionToken(SESSION_CAPABILITY_CONTEXT_KEYS.CREATE_ENTITY);
  const [getMerchantDetails] = useLazyGetMerchantDetailsQuery();
  const selectedCountryMetadata = state?.country?.metadata;

  const handleCountryChange = (items: OptionsType[]) => {
    const item = items?.[0];

    setState((current) => ({ ...current, country: item, intent: data?.country_products_map?.[item?.value] ?? [] }));
  };

  const handleAddEntity = () => {
    state?.country &&
      state?.name &&
      state?.intent &&
      sessionToken &&
      addEntity({
        geography: selectedCountryMetadata?.code?.toString(),
        name: state?.name,
        product_intent: state.intent,
        idempotencyHeader: sessionToken,
      })
        .then((data: MapAny) => {
          if (!data?.error) {
            handleClose?.();
            refreshMerchant && getMerchantDetails?.();
          }
        })
        .finally(createSessionToken);
  };

  const addProductToIntent = (product: PRODUCT_TYPES) => {
    const updatedState = { ...state };
    const productsToAdd = [product, ...(getProductDetails(product, data?.offered_products)?.depends_on ?? [])];

    const countryValue = [...(updatedState.intent ?? [])];

    productsToAdd.forEach((product) => {
      if (!countryValue.includes(product)) {
        countryValue.push(product);
      }
    });

    updatedState.intent = countryValue;

    setState(updatedState);
  };

  const removeProductFromIntent = (product: PRODUCT_TYPES) => {
    const updatedState = { ...state };
    const countryValue = [...(state.intent ?? [])];

    const productsToSplice = [product, ...(checkDependentProducts(product, data?.offered_products) ?? [])];

    productsToSplice.forEach((product) => {
      if (countryValue.includes(product)) {
        countryValue.splice(countryValue.indexOf(product), 1);
      }
    });

    updatedState.intent = countryValue;

    setState(updatedState);
  };

  const handleProductClick = (product: PRODUCT_TYPES, checked: boolean) => {
    if (checked) {
      removeProductFromIntent(product);
    } else {
      addProductToIntent(product);
    }
  };

  const handleIntentClick = (product: PRODUCT_TYPES) =>
    selectedCountryMetadata?.code &&
    data?.country_products_map?.[selectedCountryMetadata?.code]?.includes(product) &&
    handleProductClick(product, state?.intent?.includes(product) ?? false);

  const handleClose = () => {
    setState({});
    onClose?.();
  };

  return (
    <Popup isOpen={isOpen} handleVisibility={handleClose} className='tw-bg-white tw-w-[530px] tw-rounded-2.5 !tw-p-0'>
      <div>
        <div className='tw-h-16 tw-px-8 tw-py-3 tw-flex tw-items-center tw-justify-between' onClick={handleClose}>
          <span className='f-18-500'>Add company</span>
          <SvgSpriteLoader
            id='x-close'
            iconCategory={ICON_SPRITE_TYPES.GENERAL}
            width={16}
            height={16}
            className='tw-p-1 tw-rounded-full tw-bg-GRAY_2 tw-cursor-pointer'
          />
        </div>
        <div className='tw-px-8 tw-w-full'>
          <Input
            inputFieldProps={{
              inputTagProps: {
                onChange: (e) => setState((current) => ({ ...current, name: e.target.value })),
                value: state?.name,
                id: 'COMPANY_DETAILS_ADD_ENTITY_NAME',
              },
              size: 'MEDIUM',
            }}
            labelProps={{
              title: 'Business name',
            }}
            showLabel
          />
          <CountryPicker
            label='Business registration country'
            showDialCode={false}
            onMenuItemClick={handleCountryChange}
            id='COUNTRY_DETAILS_ADD_COUNTRY_DROPDOWN'
            selectedValueKey={'name'}
            overrideClassName='tw-w-full tw-my-4'
            labelClassName='tw-text-TEXT_PRIMARY f-14-500'
          />
          {selectedCountryMetadata?.code && (
            <>
              {!data?.country_products_map?.[selectedCountryMetadata?.code ?? 'US']?.length ? (
                <div className='f-12-300 tw-text-TEXT_SECONDARY'>
                  We are yet to make our service available at this location
                </div>
              ) : (
                <>
                  <span className='tw-text-TEXT_PRIMARY f-14-500'>Available services</span>

                  <div
                    className={`tw-flex f-10-600 tw-text-TEXT_SECONDARY tw-mb-1 tw-w-full tw-justify-start -tw-ml-4 tw-gap-3`}
                  >
                    {Object.keys(PRODUCT_TYPES)?.map((eachProduct) => {
                      const productDisabled =
                        !data?.country_products_map?.[selectedCountryMetadata?.code ?? 'US']?.includes(
                          eachProduct as PRODUCT_TYPES
                        ) || false;

                      const productDetails = data?.offered_products?.[eachProduct as PRODUCT_TYPES];

                      return (
                        <Tooltip
                          key={eachProduct}
                          disabled={!productDisabled}
                          position={TooltipPositions.TOP}
                          tooltipBody={
                            <div className='tw-w-64 f-12-300'>
                              {productDetails?.display_name} is not available in {selectedCountryMetadata?.name} at this
                              time. We are working hard to make it available and hope to onboard you as soon as possible
                            </div>
                          }
                          className='tw-basis-0 tw-w-full tw-flex-grow tw-max-w-fit'
                        >
                          <div
                            className={`tw-text-center tw-flex tw-items-center f-14-300 ${
                              productDisabled
                                ? 'tw-text-TEXT_SECONDARY tw-cursor-not-allowed'
                                : 'tw-text-TEXT_PRIMARY tw-cursor-pointer'
                            }`}
                            key={eachProduct}
                            onClick={() => handleIntentClick?.(eachProduct as PRODUCT_TYPES)}
                          >
                            <CheckBox
                              disabled={productDisabled}
                              checked={state?.intent?.includes(eachProduct) ?? false}
                              id={`ADD_COUNTRY_INTENT_CHECKBOX_${eachProduct}`.toUpperCase()}
                              eventCallback={trackMixpanel}
                              onPress={defaultFn}
                            />

                            {sentenceCase(
                              data?.offered_products?.[eachProduct as PRODUCT_TYPES]?.display_name?.toLowerCase()
                            )}
                          </div>
                        </Tooltip>
                      );
                    })}
                  </div>
                </>
              )}
            </>
          )}
          {error && (
            <Label
              description={error?.data?.error?.message}
              descriptionClass='tw-text-xs tw-text-RED_SECONDARY tw-py-4'
            />
          )}
          <div className='tw-flex tw-justify-end tw-gap-2 tw-py-3'>
            <Button
              buttonProps={{
                btnType: BUTTON_TYPES.SECONDARY,
                size: BUTTON_SIZE_TYPES.MEDIUM,
                id: 'COMPANY_DETAILS_ADD_ENTITY_CLOSE',
                eventCallback: trackMixpanel,
                onClick: handleClose,
                disabled: isLoading,
              }}
            >
              Cancel
            </Button>
            <Button
              buttonProps={{
                btnType: BUTTON_TYPES.PRIMARY,
                size: BUTTON_SIZE_TYPES.MEDIUM,
                id: 'COMPANY_DETAILS_ADD_ENTITY_CONFIRM',
                eventCallback: trackMixpanel,
                onClick: handleAddEntity,
                disabled: !selectedCountryMetadata || !state?.name || !state?.intent?.length,
                isLoading: isLoading,
                wrapperClass: 'tw-overflow-clip',
              }}
            >
              Add company
            </Button>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default AddEntityPopup;
