import { MouseEventHandler } from 'react';
import { downloadZip } from 'client-zip';
import { ROMA_HOST, ZAMP_INVEST_HOST } from 'constants/config';
import { MONTH_NAME, TIMEZONES } from 'constants/date';
import { SCREEN_BREAKPOINTS, T_AND_C_CODE } from 'constants/index';
import { StatusType } from 'constants/kyb';
import { CRYPTO_ADDRESS_REGEX } from 'constants/regex';
import { TabsOptionsTypes } from 'constants/settings';
import { KEYS_DELIMITER } from 'constants/shortcuts';
import { store } from 'store';
import { ACTIVE_IF_COMPARATOR_TYPES, MapAny, PRODUCT_TYPES } from 'types';
import { FieldType, StringMap } from 'types/kyb';
import { INPUT_FILE_FORMATS } from 'types/mime';
import { WalletAccountDetails } from 'types/romaAccounts';

export const getColorValue = () => Math.floor(Math.random() * 64) + 80;

export const getRandomColor = () => `rgb(${getColorValue()}, ${getColorValue()}, ${getColorValue()}`;

export const getFirstLetters = (str: string) =>
  str
    .split(' ')
    .map((word, index) => {
      if (index > 1 || !word.length) return null;
      else return word[0].toUpperCase();
    })
    .join('');

export const getYearMonDayFormat = (date: string) => {
  const tempDate = new Date(date);

  return tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate();
};

export const changeTimeZone = (date: Date | string, timeZone: string) => {
  let convertedDate;

  if (typeof date === 'string' && date.endsWith('UTC')) {
    const standardizedDateString = date.replace(/(\.\d+)? \+\d+ UTC$/, '');

    convertedDate = new Date(
      new Date(standardizedDateString).toLocaleString('en-US', {
        timeZone,
      })
    );
  } else if (typeof date === 'string')
    convertedDate = new Date(
      new Date(date).toLocaleString('en-US', {
        timeZone,
      })
    );
  else {
    convertedDate = new Date(
      date.toLocaleString('en-US', {
        timeZone,
      })
    );
  }

  return convertedDate;
};

export const createUTCDateObject = (dateString: Date | string) => {
  return changeTimeZone(dateString, TIMEZONES.UTC);
};

export const getMonDayFormat = (date: string) => {
  const tempDate = createUTCDateObject(date);

  return `${MONTH_NAME[`${tempDate.getMonth()}`]} ${tempDate.getDate()}`;
};

export const getDayMonYearFormat = (date: string) => {
  const tempDate = new Date(date);

  return `${getNumberSuffix(tempDate.getDate())}  ${MONTH_NAME[`${tempDate.getMonth()}`]}, ${tempDate.getFullYear()}`;
};

export const getMonDayYearFormat = (date: string) => {
  const tempDate = new Date(date);

  return `${MONTH_NAME[`${tempDate.getMonth()}`]} ${tempDate.getDate()}, ${tempDate.getFullYear()}`;
};

export const getUSDayMonYearFormat = (date: string) => {
  const tempDate = new Date(date.split('T')[0]);

  return `${getNumberSuffix(tempDate.getDate())}  ${MONTH_NAME[`${tempDate.getMonth()}`]}, ${tempDate.getFullYear()}`;
};

export const getNumberSuffix = (i: number) => {
  const j = i % 10,
    k = i % 100;

  if (j == 1 && k != 11) return i + 'st';
  if (j == 2 && k != 12) return i + 'nd';
  if (j == 3 && k != 13) return i + 'rd';

  return i + 'th';
};

export const getTime = (date: string) => {
  const tempDate = new Date(date).toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
  const dateArray = tempDate.split(',');

  return dateArray[1];
};

export const getSelectFieldValue = (fields: Array<any>, value: string) => {
  if (!value) return '';

  return fields.find((field: any) => field.value === value);
};

export const isValidCryptoAddress = (address: string | null) => {
  if (!address) return false;

  return !!address.match(CRYPTO_ADDRESS_REGEX);
};

export const getRandomId = () => `payout_${Math.floor(1000 + Math.random() * 9000)}`;

export const isSameDate = (firstDate: string | null, secondDate: string | null) => {
  if (!firstDate || !secondDate) return true;

  const date1 = new Date(firstDate);
  const date2 = new Date(secondDate);

  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export const copyToClipBoard = (copyText: string) => {
  if ('clipboard' in navigator) {
    navigator.clipboard.writeText(copyText);
  } else {
    const textField = document.createElement('textarea');

    textField.innerText = copyText;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  }
};

export const getTrimmedAmount = (amount: any) => {
  return amount?.toString()?.slice(0, amount?.toString()?.indexOf('.') + 7);
};

export const getCommaSeparatedNumber = (num?: number, maximumFractionDigits = 2) =>
  num ? num.toLocaleString('en-US', { maximumFractionDigits, minimumFractionDigits: 2 }) : 0.0;

export const getCommaSeparatedNumberForInput = (num: string) => num.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const getTimeDiffInHour = (oldTime: any, newTime: any) =>
  Math.floor((new Date(newTime).getTime() - new Date(oldTime).getTime()) / 3600000);

export const getFormattedCurrency = (input: any) => {
  const amount = parseInt(input);

  let formattedAmount = input;

  if (amount >= 1000000000) formattedAmount = (amount / 1000000000).toString().split('.')[0] + ' Billion';
  else if (amount >= 1000000) formattedAmount = (amount / 1000000).toString().split('.')[0] + ' Million';

  return formattedAmount;
};

export const getFromJSON = (object: any, key: string) => {
  if (key in object) return object[key];

  return undefined;
};

export const getInputValueFromJSON = (object: any, key: string) => {
  // console.log(key)
  let value = getFromJSON(object, `${key}-select`);

  if (value) return value;
  value = getFromJSON(object, `${key}-range_slider`);
  if (value) return value;
  value = getFromJSON(object, `${key}-region_picker`);
  if (value) return value;
  value = getFromJSON(object, `${key}-text`);
  if (value) return value;
  value = getFromJSON(object, `${key}-number`);
  if (value) return value;
  // console.log(`Not Found ${key}`)

  return null;
};

export const stitchEmptyObjectKeysWithJSON = (objectData: any, jsonData: any) => {
  const newObjectData = JSON.parse(JSON.stringify(objectData));

  for (let i = 1; i < newObjectData.length; i++) {
    const objectKeys = Object.keys(newObjectData[i]);

    objectKeys.map((objectKey) => {
      if (newObjectData[i][objectKey] == null) {
        newObjectData[i][objectKey] = getInputValueFromJSON(jsonData, objectKey);
        console.log(`Updation ${objectKey}: `);
        console.log(newObjectData[i][objectKey]);
      } else {
        console.log(`${objectKey} Not Null or Not Found while stitching`);
      }
    });
  }

  return newObjectData;
};

export const checkIfNumber = (e: any) => {
  e = e || window.event;

  const charCode = typeof e.which == 'undefined' ? e.keyCode : e.which;
  const charStr = String.fromCharCode(charCode);

  if (!charStr.match(/^[0-9.]+$/)) e.preventDefault();
};

export const isValidURL = (str: string): boolean => {
  let url;

  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const extractFileNameFromUrl = (url: string): string => {
  return url.split('/').pop()?.split('?')[0] ?? '';
};

export const fetchFileBlob = async (url: string) => {
  const file = await fetch(url)
    .then((response) => response.blob())
    .then((data) => data);

  return file;
};

export const downloadFile = async (
  url: string,
  setIsLoading?: (flag: boolean) => void,
  extension?: INPUT_FILE_FORMATS
) => {
  try {
    const fileName = extractFileNameFromUrl(url) + (extension ?? '');
    const fileBlob = await fetchFileBlob(url).then((data) => data);

    const aTag = document.createElement('a');

    aTag.setAttribute('href', URL.createObjectURL(fileBlob));
    fileName && aTag.setAttribute('download', fileName);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  } finally {
    setIsLoading?.(false);
  }
};

export const checkScreenBreakpoint = (width: number, height: number) =>
  width && height ? width < SCREEN_BREAKPOINTS.MIN_WIDTH || height < SCREEN_BREAKPOINTS.MIN_HEIGHT : false;

export const copyMultilineData = (json: StringMap): void => {
  const replaceList = [
    {
      regex: /{/g,
    },
    {
      regex: /}/g,
    },
    {
      regex: /(?<="),/g,
      replace: '\n',
    },
    {
      regex: /"/g,
    },
    {
      regex: /:/g,
      replace: ' : ',
    },
  ];
  let jsonString = JSON.stringify(json);

  replaceList.forEach((item) => {
    const { regex, replace = '' } = item;

    jsonString = jsonString.replace(regex, replace);
  });
  copyToClipBoard(jsonString);
};

export const checkIsObjectValuesempty = (obj: Record<string, string | number>) => {
  return Object.values(obj).some((val) => val === null || val === '');
};

export const checkIsObjectEmpty = (obj: MapAny, ignoreKeys?: string[]) => {
  if (!obj) return true;

  if (typeof obj === 'object' && !Object.keys(obj).length) return true;

  if (!ignoreKeys?.length && typeof obj === 'object' && !Object.keys(obj).length) return true;

  if (ignoreKeys?.length && typeof obj === 'object') {
    const keys = Object.keys(obj);

    if (JSON.stringify(keys.sort()) === JSON.stringify(ignoreKeys?.sort())) return true;
  }

  return false;
};

export const splitArrayIntoSubArrays = (arrayInput: Array<unknown>, subArraySize: number): Array<unknown> => {
  if (subArraySize > 0) {
    const outputArray = [];

    for (let i = 0; i < arrayInput.length; i += subArraySize) {
      const chunk = arrayInput.slice(i, i + subArraySize);

      outputArray.push(chunk);
    }

    return outputArray;
  }

  return arrayInput;
};

export const showGlobalAccountDetails = (currencyCode = '', internationalDetails?: WalletAccountDetails) => {
  let showInternational = false;
  let detailsMapOutside: StringMap[] = [];

  if (internationalDetails) {
    detailsMapOutside = [
      {
        title: 'Account Holder Name',
        value: internationalDetails.account_holder_name,
      },
      {
        title: 'Account number',
        value: internationalDetails.account_number,
      },
      {
        title: internationalDetails.routing_code_type_1,
        value: internationalDetails.routing_code_value_1,
      },
      {
        title: internationalDetails.routing_code_type_2 ?? '',
        value: internationalDetails.routing_code_value_2 ?? '',
      },
      {
        title: 'Bank Name',
        value: internationalDetails.bank_display_name,
      },
      {
        title: 'Bank Address',
        value: internationalDetails.bank_address,
      },
    ];
    showInternational = internationalDetails?.currency_code === currencyCode;
  }

  return { detailsMapOutside, showInternational };
};

export const getValidTabs = (
  tabsOptions: TabsOptionsTypes[],
  kybStatus: string,
  role: string,
  isPayoutsEnabled: boolean,
  isTreasuryEnabled: boolean,
  isBusinessEnabled: boolean,
  isIndividual = false,
  isSuperUser = false
) => {
  const tabs: TabsOptionsTypes[] = [];

  tabsOptions.forEach((item: TabsOptionsTypes) => {
    if (item.isHidden) return;
    if (item.roles && item.roles.length && !item.roles.includes(role)) return;
    if (item.needKybSuccess && kybStatus !== StatusType.SUCCESS) return;
    if (typeof item.isIndividual === 'boolean' && isIndividual !== item.isIndividual) return;
    if (item.isSuperUser && !isSuperUser) return;
    if (
      !(
        (item.isTreasuryEnabled && isTreasuryEnabled) ||
        (item.isPayoutEnable && isPayoutsEnabled) ||
        (item.isBusinessEnabled && isBusinessEnabled)
      )
    )
      return;
    tabs.push(item);
  });

  return tabs;
};

export const getValuesfromDropwdown = (value: any) => {
  const values: string[] = [];

  Array.isArray(value) && value?.forEach((item: any) => values.push(item.value));

  return values;
};

export const formatNumberToTwoDigits = (number: number) => {
  return number?.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
};

export const CheckValidOtp = (value: string, length = 6) => {
  return value?.trim().length === length;
};

/**
 * creates and downloads zip files from list of file urls
 * @param downloadLinks - list of file urls
 * @param setIsLoading - function to change loading state in parent element
 * @param zipName - name of generated zip file
 */
export const downloadZipFile = async (
  downloadLinks: string[] = [],
  setIsLoading: (flag: boolean) => void,
  zipName = 'account_statements'
) => {
  try {
    if (downloadLinks?.length) {
      const promises = downloadLinks.map((url) => fetch(url));
      const codes = await Promise.all(promises);

      // get the ZIP stream in a Blob
      const blob = await downloadZip(codes)?.blob();

      // make and click a temporary link to download the Blob
      const link = document.createElement('a');
      const objectURL = URL.createObjectURL(blob);

      link.href = objectURL;
      link.download = `${zipName}.zip`;
      link.click();
      link.remove();
      URL.revokeObjectURL(objectURL);
    }
  } finally {
    setIsLoading(false);
  }
};

export const stopPropagationAction: MouseEventHandler<HTMLElement> = (event) => {
  event?.stopPropagation();
};

export const generateShortcutCombinationIdentifier = (value: string[] | globalThis.Set<string>) => {
  return Array.from(value)?.sort()?.join(KEYS_DELIMITER);
};

export const checkShortcutMatch = (appliedShortcut: string[], checkWith: string) => {
  return generateShortcutCombinationIdentifier(appliedShortcut) === checkWith;
};

export const checkNullOrUndefined = (value: any) => {
  return value === null || value === undefined;
};

export const canAddVault = (): boolean => {
  const { user } = store.getState();
  const merchantId = user?.user?.merchant_id;
  const AddVaultDisabledMerchants = ['clp955_7iHG5cWvgQJWcUpcYHgmG5_05_25'];

  return !AddVaultDisabledMerchants.includes(merchantId);
};

/**
 *
 * @param text e.g. a-b, a b
 * @returns uppercase snake string, e.g. A_B
 */
export const convertToSnakeUppercase: (text: string) => string = (text = '') =>
  text.split(/[ -]+/).join('_').toUpperCase();

/**
 *
 * @param text e.g. AbcdEfgh
 * @returns lowercase snake string, e.g. abcd_efgh
 */
export const pascalCaseToSnakeLowercase: (text: string) => string = (text = '') =>
  text
    .split(/(?=[A-Z])/)
    .join('_')
    .toLowerCase();

/**
 *
 * @param text e.g. AbcdEfgh
 * @returns capitalized upper case string e.g. ABCD EFGH
 */
export const pascalCaseToCapitalizedUpperCase: (text: string) => string = (text = '') =>
  text
    .split(/(?=[A-Z])/)
    .join(' ')
    .toUpperCase();

/**
 *
 * @param text e.g. Abcd Efgh
 * @returns sentence casew e.g. Abcd efgh
 */
export const sentenceCase = (str: string) => {
  if (str === null || str === '') return '';
  else str = str.toString();

  return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substring(1, txt.length).toLowerCase());
};

/**
 * Change number to prefixed with 0.
 * @param value number to transform
 * @param digits number of digits to maintain after prefix with 0
 * @returns string
 */
export const numberToSpecificDigits: (value: number, digits?: number) => string = (value, digits = 2) =>
  value.toLocaleString('en-US', {
    minimumIntegerDigits: digits,
    useGrouping: false,
  });

/**
 *
 * @param currencyCode
 * @returns name from currency config
 */
export const getCurrencyName: (currencyCode: string) => string = (currencyCode) => {
  const { config } = store.getState();

  return config?.currencyCodeAndNameMap?.[currencyCode] ?? '';
};

export const checkActiveIfValid = (field: FieldType, values: MapAny, allValues?: MapAny[]) => {
  if (field.active_if) {
    return field.active_if.some((eachActiveIf) => {
      const requiredSection = allValues?.find((item) => !!item[eachActiveIf.key]);

      const requiredValue = requiredSection?.[eachActiveIf.key] ?? values[eachActiveIf.key];

      if (typeof requiredValue === 'object' && Array.isArray(requiredValue)) {
        return requiredValue.includes(eachActiveIf.value);
      } else if (typeof requiredValue === 'object' && requiredValue?.value) {
        return eachActiveIf.comparator === ACTIVE_IF_COMPARATOR_TYPES.EQUAL
          ? requiredValue?.value === eachActiveIf.value
          : requiredValue?.value !== eachActiveIf.value;
      } else {
        return eachActiveIf.comparator === ACTIVE_IF_COMPARATOR_TYPES.EQUAL
          ? requiredValue === eachActiveIf.value
          : requiredValue !== eachActiveIf.value;
      }
    });
  }

  return true;
};

export const isDashboardShareableFormUrl = (url: string) => {
  return url.includes('dashboard.zamp.finance/form/');
};

export const checkIsIntegerOrFloat = (value: string) => {
  const number = parseFloat(value);

  return !isNaN(number) && isFinite(number);
};

export const addUniqueObjectToArray = (array: any[], object: any, key = 'id') => {
  const index = array.findIndex((item) => item[key] === object[key]);

  if (index === -1) array.push(object);

  return array;
};

export const nthNumber = (number: number) => {
  return number > 0 ? ['th', 'st', 'nd', 'rd'][(number > 3 && number < 21) || number % 10 > 3 ? 0 : number % 10] : '';
};

export const objectToQueryParams = (obj: MapAny) => {
  return Object.keys(obj)
    .map((key) => {
      // Assuming that the values are primitives (not objects or arrays)
      // Encode both keys and values to make them URL-safe
      return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
    })
    .join('&');
};

export const isRomaDashboard = () => {
  return typeof window !== 'undefined' ? window?.location?.hostname?.endsWith(ROMA_HOST) : false;
};

export const isZampInvestDashboard = () => {
  return typeof window !== 'undefined' ? window?.location?.hostname?.endsWith(ZAMP_INVEST_HOST) : false;
};

export const getTncServiceCode = (entityIntents: PRODUCT_TYPES[]) => {
  if (entityIntents.includes(PRODUCT_TYPES.banking_account) && entityIntents.includes(PRODUCT_TYPES.treasury_account))
    return T_AND_C_CODE.ROMA;
  if (entityIntents.includes(PRODUCT_TYPES.banking_account)) return T_AND_C_CODE.BANKING;
  if (entityIntents.includes(PRODUCT_TYPES.treasury_account)) return T_AND_C_CODE.TREASURY;

  return T_AND_C_CODE.ROMA;
};
