import { FLOW_PERMISSION_PROPERTIES } from 'constants/accessConstants';
import { ACCESS_HEADER_RESOURCE_KEY, PLATFORM_IDEMPOTENCY_HEADER_KEY } from 'constants/config';
import { APITags, REQUEST_TYPES } from 'constants/index';
import baseApi from 'services/api';
import { EntityResponseType } from 'types/accountsApi.types';
import { AddEntitiesRequest, DeleteEntitiesRequest } from 'types/entitiesApi.types';
import { accessBasedQueryWrapper } from 'utils/access';

const Entities = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    addEntity: builder.mutation<void, AddEntitiesRequest>({
      query: (payload) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.permissionId, {
          url: 'v1/entities/',
          method: REQUEST_TYPES.POST,
          body: { geography: payload.geography, name: payload.name, product_intent: payload.product_intent },
          headers: {
            [PLATFORM_IDEMPOTENCY_HEADER_KEY]: payload.idempotencyHeader,
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.scope,
          },
        }),
      transformResponse: ({ data }) => data,
      invalidatesTags: [APITags.GET_COMPANY_DETAILS, APITags.GET_NAVIGATION],
    }),
    deleteEntity: builder.mutation<void, DeleteEntitiesRequest>({
      query: (payload) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.permissionId, {
          url: `v1/entities/${payload.entityId}`,
          method: REQUEST_TYPES.DELETE,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.scope,
            [PLATFORM_IDEMPOTENCY_HEADER_KEY]: payload.idempotencyHeader,
          },
        }),
      transformResponse: ({ data }) => data,
      invalidatesTags: [APITags.GET_COMPANY_DETAILS, APITags.GET_MERCHANT_DETAILS, APITags.GET_NAVIGATION],
    }),
    getEntitiesById: builder.query<EntityResponseType[], string>({
      query: (param) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.VIEW_MERCHANT_ACCOUNTS.permissionId, {
          url: `v1/entities/?products=${param}`,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.VIEW_MERCHANT_ACCOUNTS.scope,
          },
        }),
      transformResponse: ({ data }) => data,
      providesTags: [APITags.GET_ALL_ENTITIES],
    }),
    getEntitiesForRole: builder.query<EntityResponseType[], string>({
      query: (product) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_TEAM.permissionId, {
          url: `v1/entities/roles?products=${product}`,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_TEAM.scope,
          },
        }),
      transformResponse: ({ data }) => data,
      providesTags: [APITags.GET_ALL_ENTITIES],
    }),
  }),
});

export const { useAddEntityMutation, useDeleteEntityMutation, useGetEntitiesByIdQuery, useGetEntitiesForRoleQuery } =
  Entities;
