import { useState } from 'react';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { PRODUCT_INFORMATION, PRODUCT_INFORMATION_V2 } from 'modules/intent/intent.constants';
import { PRODUCT_TYPES_V2 } from 'modules/intent/intent.types';
import Image from 'next/image';
import { MapAny, PRODUCT_TYPES } from 'types';

interface IntentProductDetailsProps {
  product?: PRODUCT_TYPES;
  offeredProducts?: MapAny;
  productV2?: PRODUCT_TYPES_V2;
}

const IntentProductDetails: React.FC<IntentProductDetailsProps> = ({
  product = PRODUCT_TYPES.banking_account,
  productV2,
}) => {
  const [productDetailsSelected, setProductDetailsSelected] = useState<string>('');

  const details = productV2 ? PRODUCT_INFORMATION_V2?.[productV2] : PRODUCT_INFORMATION?.[product];
  const detailsV2 = productV2 ? PRODUCT_INFORMATION_V2?.[productV2] : null;

  return (
    <div
      key={product}
      className={`tw-bg-BASE_SECONDARY hover:tw-bg-BASE_PRIMARY tw-rounded-2.5 tw-group tw-overflow-hidden ${
        productDetailsSelected ? 'tw-bg-BASE_PRIMARY' : 'tw-bg-BASE_SECONDARY hover:tw-bg-BASE_PRIMARY'
      }`}
    >
      <div
        className='tw-p-4 tw-flex tw-items-center tw-justify-between tw-h-14 tw-cursor-pointer'
        onClick={() => setProductDetailsSelected(product === productDetailsSelected ? '' : product)}
      >
        <div className='tw-flex tw-items-center'>
          {!!detailsV2 && <Image src={detailsV2?.icon} alt='product_icon' width={28} height={24} className='tw-mr-4' />}
          <span className='f-16-400'>{details?.title}</span>
        </div>
        <SvgSpriteLoader
          id='chevron-right'
          iconCategory={ICON_SPRITE_TYPES.ARROWS}
          height={24}
          width={24}
          className={`group-hover:tw-block tw-min-w-fit tw-duration-300 tw-block ${
            product === productDetailsSelected ? 'tw-rotate-90' : ''
          }`}
        />
      </div>
      <div
        className={`tw-duration-300 tw-px-4 ${
          product === productDetailsSelected ? 'tw-h-auto tw-max-h-screen tw-mt-2' : 'tw-max-h-0'
        }`}
      >
        {details?.details?.map((each, index) => (
          <div key={index} className='tw-pb-6'>
            <div className='f-13-500 tw-text-TEXT_PRIMARY tw-mb-3'>{each?.title}</div>
            <div className='f-14-300 tw-text-TEXT_SECONDARY'>{each?.description}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IntentProductDetails;
