import { CRYPTO_PAYMENT_GATEWAY, ON_OFF_RAMP, USD_BUSINESS_BANKING } from 'constants/icons';
import { OptionsType } from 'destiny/dist/types/dropdown';
import { PRODUCT_TYPES_V2, ProductInformationType, ProductListItemType } from 'modules/intent/intent.types';
import { PRODUCT_TYPES } from 'types';

export const PRODUCT_INFORMATION = {
  [PRODUCT_TYPES.treasury_account]: {
    title: 'Treasury | Earn up to 5.4% with U.S. Treasuries',

    details: [
      {
        title: 'Investment account in your name',
        description:
          'Account and assets held directly in your name with BNY Mellon Pershing. BNY Mellon is the world’s largest custodian with ~$47 trillion in assets',
      },
      {
        title: 'High-grade investment products',
        description:
          'Our algorithm recommends portfolios customized to your unique liquidity needs, all in a few clicks',
      },
      {
        title: 'Access your funds anytime',
        description:
          'The U.S. Treasury market is one of the most liquid financial markets in the world. Money available in less than 1 business day',
      },
    ],
  },
  [PRODUCT_TYPES.banking_account]: {
    title: 'Business Banking | Multi-entity, multi-curency accounts',

    details: [
      {
        title: 'Seamlessly manage accounts for multiple entities',
        description:
          'Open unlimited multi-currency accounts for your companies to receive payments from global customers faster‍',
      },
      {
        title: 'Real-time payments in 180+ countries',
        description: 'Pay your vendors or contractors locally across 180+ countries in over 100 currencies',
      },
      {
        title: 'Make bulk payouts, in a single click',
        description: 'Upload a CSV file, and make thousands of payments with one click',
      },
      {
        title: 'Seamless integration with your favorite accounting software',
        description:
          'Connect accounting software for all of your entities, so your books are closed faster, without any manual efforts',
      },
    ],
  },
  [PRODUCT_TYPES.cards]: null,
};

export const PRODUCT_INFORMATION_V2: Record<PRODUCT_TYPES_V2, ProductInformationType | null> = {
  [PRODUCT_TYPES_V2.USD_BANKING]: {
    title: 'USD Business Banking',
    details: [
      {
        title: 'Seamlessly manage accounts for multiple entities',
        description:
          'Open unlimited multi-currency accounts for your companies to receive payments from global customers faster‍',
      },
      {
        title: 'Real-time payments in 180+ countries',
        description: 'Pay your vendors or contractors locally across 180+ countries in over 100 currencies',
      },
      {
        title: 'Make bulk payouts, in a single click',
        description: 'Upload a CSV file, and make thousands of payments with one click',
      },
      {
        title: 'Seamless integration with your favorite accounting software',
        description:
          'Connect accounting software for all of your entities, so your books are closed faster, without any manual efforts',
      },
    ],
    icon: USD_BUSINESS_BANKING,
  },
  [PRODUCT_TYPES_V2.ON_OFF_RAMP]: {
    title: 'On/Off ramp',
    details: [
      {
        title: 'Seamlessly manage accounts for multiple entities',
        description:
          'Open unlimited multi-currency accounts for your companies to receive payments from global customers faster‍',
      },
      {
        title: 'Real-time payments in 180+ countries',
        description: 'Pay your vendors or contractors locally across 180+ countries in over 100 currencies',
      },
      {
        title: 'Make bulk payouts, in a single click',
        description: 'Upload a CSV file, and make thousands of payments with one click',
      },
      {
        title: 'Seamless integration with your favorite accounting software',
        description:
          'Connect accounting software for all of your entities, so your books are closed faster, without any manual efforts',
      },
    ],
    icon: ON_OFF_RAMP,
  },
  [PRODUCT_TYPES_V2.CRYPTO_PAYMENT_GATEWAY]: {
    title: 'Crypto Payment Gateway',
    details: [
      {
        title: 'Seamlessly manage accounts for multiple entities',
        description:
          'Open unlimited multi-currency accounts for your companies to receive payments from global customers faster‍',
      },
      {
        title: 'Real-time payments in 180+ countries',
        description: 'Pay your vendors or contractors locally across 180+ countries in over 100 currencies',
      },
      {
        title: 'Make bulk payouts, in a single click',
        description: 'Upload a CSV file, and make thousands of payments with one click',
      },
      {
        title: 'Seamless integration with your favorite accounting software',
        description:
          'Connect accounting software for all of your entities, so your books are closed faster, without any manual efforts',
      },
    ],
    icon: CRYPTO_PAYMENT_GATEWAY,
  },
  [PRODUCT_TYPES_V2.OTC_DESK]: {
    title: 'OTC desk',
    details: [
      {
        title: 'Seamlessly manage accounts for multiple entities',
        description:
          'Open unlimited multi-currency accounts for your companies to receive payments from global customers faster‍',
      },
      {
        title: 'Real-time payments in 180+ countries',
        description: 'Pay your vendors or contractors locally across 180+ countries in over 100 currencies',
      },
      {
        title: 'Make bulk payouts, in a single click',
        description: 'Upload a CSV file, and make thousands of payments with one click',
      },
      {
        title: 'Seamless integration with your favorite accounting software',
        description:
          'Connect accounting software for all of your entities, so your books are closed faster, without any manual efforts',
      },
    ],
    icon: CRYPTO_PAYMENT_GATEWAY,
  },
};

export const PRODUCT_TYPES_LIST: ProductListItemType[] = [
  {
    label: 'USD Business Banking',
    description: 'Pay your Business expenses - Salary and Vendor payments in fiat or crypto',
    value: PRODUCT_TYPES_V2.USD_BANKING,
  },
  {
    label: 'On/Off Ramp',
    value: PRODUCT_TYPES_V2.ON_OFF_RAMP,
    description: 'Convert between fiat and crypto seamlessly',
  },
  {
    label: 'Crypto Payment Gateway',
    value: PRODUCT_TYPES_V2.CRYPTO_PAYMENT_GATEWAY,
    description: 'Accept Crypto from your customers and get settled in Fiat',
  },
  {
    label: 'OTC Desk',
    value: PRODUCT_TYPES_V2.OTC_DESK,
    description: 'For trades above USD 1 Million',
  },
];

export const ON_RAMP_CURRENCIES = ['AED', 'USD', 'EUR'];
export const OFF_RAMP_CURRENCIES = ['USD', 'EUR', 'INR'];

export const MONTHLY_TRADE_VOLUMES: OptionsType[] = [
  { label: 'USD 10,000 - USD 100,000', value: 'USD 10,000 - USD 100,000' },
  { label: 'USD 100,000 - USD 500,000', value: 'USD 100,000 - USD 500,000' },
  { label: 'USD 500,000 - USD 2,000,000', value: 'USD 500,000 - USD 2,000,000' },
  { label: 'USD 2,000,000 - USD 5,000,000', value: 'USD 2,000,000 - USD 5,000,000' },
  { label: 'USD 5,000,000 and above', value: 'USD 5,000,000 and above' },
];
