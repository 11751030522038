import { useEffect, useRef, useState } from 'react';
import { useGetMerchantCompanyDetailsQuery, usePatchIntentMutation } from 'api/merchant';
import { FLOW_PERMISSION_PROPERTIES } from 'constants/accessConstants';
import { ROMA_LOGO, ZAMP_LOGO } from 'constants/icons';
import { SESSION_CAPABILITY_CONTEXT_KEYS } from 'constants/index';
import { LAUNCH_DARKLY_FLAGS } from 'constants/launchDarkly';
import { ROUTES_PATH } from 'constants/routeConfig';
import { Alert } from 'destiny/dist/components/molecules/alert';
import { Button } from 'destiny/dist/components/molecules/button';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { AlertTypes } from 'destiny/dist/constants/molecules/alert';
import { BUTTON_SIZE_TYPES, BUTTON_TYPES } from 'destiny/dist/constants/molecules/buttons';
import { MapAny } from 'destiny/dist/types';
import { useAppSelector } from 'hooks/toolkit';
import { useSessionToken } from 'hooks/useSessionToken';
import EntityIntentRow from 'modules/intent/EntityIntentRow';
import EntityIntentRowV2 from 'modules/intent/EntityIntentRowV2';
import {
  MONTHLY_TRADE_VOLUMES,
  OFF_RAMP_CURRENCIES,
  ON_RAMP_CURRENCIES,
  PRODUCT_INFORMATION,
  PRODUCT_INFORMATION_V2,
} from 'modules/intent/intent.constants';
import { OnOffRampConfig, PRODUCT_TYPES_V2 } from 'modules/intent/intent.types';
import { checkDependentProducts, getProductDetails } from 'modules/intent/intent.utils';
import IntentProductDetails from 'modules/intent/IntentProductDetails';
import IntentSkeleton from 'modules/intent/IntentSkeleton';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { RootState } from 'store';
import { OfferedProductsType, PRODUCT_TYPES } from 'types/index';
import { isRomaDashboard } from 'utils/common';
import { getVariation } from 'utils/launchDarkly';
import { trackMixpanel } from 'utils/mixpanel';
import AddEntity from 'components/add-entity/AddEntity';
import { SkeletonTypes } from 'components/skeletons/types';
import AccessWrapper from 'components/wrappers/AccessWrapper';
import CommonWrapper from 'components/wrappers/CommonWrapper';

const IntentPage = () => {
  const { data: companyDetails, refetch, isFetching, isError } = useGetMerchantCompanyDetailsQuery();
  const [udpateIntent, { isLoading }] = usePatchIntentMutation();
  const { sessionToken: intentSessionToken, createSessionToken: createIntentSessionToken } = useSessionToken(
    SESSION_CAPABILITY_CONTEXT_KEYS.UPDATE_PRODUCT_INTENT
  );
  const { navigation } = useAppSelector((state: RootState) => state.user);
  const [state, setState] = useState<MapAny | null>(null);
  const [entityIntent, setEntityIntent] = useState<PRODUCT_TYPES_V2[]>(Object.values(PRODUCT_TYPES_V2));
  const [error, setError] = useState<string | null>(null);
  const [onRampConfig, setOnRampConfig] = useState<OnOffRampConfig>({
    currencies: ON_RAMP_CURRENCIES,
    monthlyTradeVolumes: MONTHLY_TRADE_VOLUMES[0],
  });
  const [offRampConfig, setOffRampConfig] = useState<OnOffRampConfig>({
    currencies: OFF_RAMP_CURRENCIES,
    monthlyTradeVolumes: MONTHLY_TRADE_VOLUMES[0],
  });
  const [isOnRampSelected, setIsOnRampSelected] = useState(true);
  const [isOffRampSelected, setIsOffRampSelected] = useState(true);

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const { data } = useGetMerchantCompanyDetailsQuery();

  const router = useRouter();
  const isOtcEnabled = getVariation(LAUNCH_DARKLY_FLAGS.OTC);

  useEffect(() => {
    if (companyDetails?.entity_details?.length) {
      const updatedState = { ...(state ?? {}) };

      companyDetails?.entity_details?.forEach((eachEntity) => {
        if (!updatedState?.[eachEntity?.entity_id]?.length) {
          updatedState[eachEntity?.entity_id] = eachEntity?.product_intent?.length
            ? eachEntity?.product_intent
            : eachEntity?.offered_product_ids;
        }
      });
      setState(updatedState);
    }
  }, [companyDetails]);

  const addProductToIntent = (product: PRODUCT_TYPES, country: string) => {
    const updatedState = { ...state };
    const productsToAdd = [
      product,
      ...(getProductDetails(product, companyDetails?.offered_products)?.depends_on ?? []),
    ];

    const countryValue = [...(updatedState[country] ?? [])];

    productsToAdd.forEach((product) => {
      if (!countryValue.includes(product)) {
        countryValue.push(product);
      }
    });

    updatedState[country] = countryValue;

    setState(updatedState);
  };

  const removeProductFromIntent = (product: PRODUCT_TYPES, country: string) => {
    const updatedState = { ...state };
    const countryValue = [...(updatedState[country] ?? [])];

    const productsToSplice = [product, ...(checkDependentProducts(product, companyDetails?.offered_products) ?? [])];

    productsToSplice.forEach((product) => {
      if (countryValue.includes(product)) {
        countryValue.splice(countryValue.indexOf(product), 1);
      }
    });

    updatedState[country] = countryValue;

    setState(updatedState);
  };

  const handleProductClick = (product: PRODUCT_TYPES, country: string, checked: boolean) => {
    if (checked) {
      removeProductFromIntent(product, country);
    } else {
      addProductToIntent(product, country);
    }
  };

  const handleSubmit = () => {
    setError('');
    intentSessionToken &&
      udpateIntent({
        data: {
          intent: state ?? {},
        },
        idempotencyHeader: intentSessionToken,
      })
        .then((resp: MapAny) => {
          if (!resp?.error) {
            const route = navigation?.nav_items?.find(
              (item: MapAny) => item.key === navigation?.default_nav_key
            )?.route;

            router.push(route ?? ROUTES_PATH.HOME);
          } else {
            createIntentSessionToken();
            setError('Something went wrong. Please try again.');
            scrollContainerRef?.current?.scrollTo(0, 0);
          }
        })
        .catch(() => {
          setError('Something went wrong. Please try again.');
          createIntentSessionToken();
        });
  };

  const onIntentClick = (product: PRODUCT_TYPES_V2) =>
    setEntityIntent((prev) =>
      prev?.includes(product) ? prev?.filter((item) => item !== product) : [...prev, product]
    );

  const offeredProducts = companyDetails?.offered_products ?? ({} as OfferedProductsType);

  const productInformationDetailKeys = Object.keys(PRODUCT_INFORMATION)?.filter(
    (each) => !!PRODUCT_INFORMATION[each as PRODUCT_TYPES]
  );

  const productInformationDetailKeysV2 = Object.keys(PRODUCT_INFORMATION_V2)?.filter(
    (each) => !!PRODUCT_INFORMATION_V2[each as PRODUCT_TYPES_V2]
  );

  const layoutWidthClass = `${isOtcEnabled ? 'tw-w-[640px]' : 'tw-w-[800px]'} tw-mx-auto`;

  const isIntentSelected =
    companyDetails?.entity_details?.some((eachEntity) => !!eachEntity?.offered_product_ids?.length) &&
    companyDetails?.entity_details?.every(
      (eachEntity) => !eachEntity?.offered_product_ids?.length || !!state?.[eachEntity?.entity_id]?.length
    );

  const entitiesToShow = [...(data?.entity_details ?? [])]?.sort((a, b) =>
    a?.entity_created_at > b?.entity_created_at ? 1 : -1
  );

  const errorToShow =
    !isIntentSelected && !isFetching && companyDetails?.entity_details?.length && state !== null
      ? 'Please select at least one product from treasury, business banking and debit card for each country of business registration.'
      : error;

  const isRoma = isRomaDashboard();

  const dummyOtcEntity = { ...entitiesToShow?.[0], offered_product_ids_v2: Object.values(PRODUCT_TYPES_V2) };

  return (
    <AccessWrapper
      permissionId={FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.permissionId}
      scope={FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.scope}
      deniedComponentClassName='!tw-h-screen'
    >
      <div
        className={`tw-flex tw-flex-col tw-h-screen tw-overflow-x-visible tw-overflow-y-scroll`}
        ref={scrollContainerRef}
      >
        <Image
          src={isRoma ? ROMA_LOGO : ZAMP_LOGO}
          height={20}
          width={80}
          alt={'zamp_logo'}
          className='tw-fixed tw-top-8 tw-left-11 tw-z-20'
        />
        <div className='tw-sticky tw-top-0 tw-bg-white tw-pt-24 tw-z-10'>
          <div className={`f-24-500 tw-mx-auto ${errorToShow ? 'tw-mb-4' : 'tw-mb-9'} ${layoutWidthClass}`}>
            {`Welcome to ${isRoma ? 'Roma' : 'Zamp'}`}
          </div>
          {!!errorToShow && (
            <Alert
              alertType={AlertTypes.ERROR}
              className={`f-12-300 !tw-justify-start tw-gap-2 tw-mx-auto tw-mb-4 ${layoutWidthClass}`}
            >
              <SvgSpriteLoader
                id='info-octagon'
                iconCategory={ICON_SPRITE_TYPES.GENERAL}
                width={16}
                height={16}
                className='tw-min-w-4'
              />
              {errorToShow}
            </Alert>
          )}
        </div>
        <CommonWrapper
          isLoading={isFetching}
          skeletonType={SkeletonTypes.CUSTOM}
          isError={isError}
          refetchFunnction={refetch}
          skeleton={<IntentSkeleton className={layoutWidthClass} />}
        >
          <div className={`tw-overflow-x-visible ${layoutWidthClass}`}>
            <div className='tw-flex tw-flex-col tw-gap-9 tw-flex-grow tw-pb-28'>
              <span className={`f-14-300 tw-text-TEXT_SECONDARY`}>
                We&apos;re excited to have you on board! Please tell us more about your product requirements so we can
                tailor your experience. By default, we&apos;ve pre-selected all the products that we offer to help you
                get started quickly, but feel free to review and uncheck any that you don&apos;t currently need.
              </span>
              {!isOtcEnabled && (
                <span className={`f-13-500 tw-text-TEXT_SECONDARY tw-uppercase`}>
                  Select the products you are interested in
                </span>
              )}
              {!isOtcEnabled && !!entitiesToShow?.length && (
                <div>
                  <div className={`tw-flex f-10-600 tw-uppercase tw-text-TEXT_SECONDARY tw-mb-1`}>
                    <div className='tw-w-5/12'>Business Registration country</div>
                    {Object.keys(PRODUCT_TYPES)?.map((each) => (
                      <div className='tw-w-2/12 tw-text-center' key={each}>
                        {offeredProducts?.[each as PRODUCT_TYPES]?.display_name}
                      </div>
                    ))}
                    <div className='tw-w-2/12 tw-text-center tw-whitespace-nowrap'>Digital assets</div>
                  </div>
                  <div className={`tw-mt-3 tw-flex tw-flex-col tw-gap-3`}>
                    <>
                      {entitiesToShow?.map((eachEntity) => (
                        <EntityIntentRow
                          key={eachEntity.entity_id}
                          offeredProducts={offeredProducts}
                          onIntentClick={(country, product) =>
                            eachEntity?.offered_product_ids?.includes(product as PRODUCT_TYPES) &&
                            handleProductClick(
                              product as PRODUCT_TYPES,
                              eachEntity?.entity_id,
                              state?.[eachEntity.entity_id]?.includes(product)
                            )
                          }
                          value={state?.[eachEntity.entity_id]}
                          details={eachEntity}
                          merchantName={companyDetails?.merchant_name}
                        />
                      ))}
                    </>
                  </div>
                </div>
              )}
              {isOtcEnabled && !!entitiesToShow?.length && (
                <EntityIntentRowV2
                  key={dummyOtcEntity.entity_id}
                  onIntentClick={onIntentClick}
                  value={entityIntent}
                  details={dummyOtcEntity}
                  merchantName={companyDetails?.merchant_name}
                  onRampConfig={onRampConfig}
                  offRampConfig={offRampConfig}
                  setOnRampConfig={setOnRampConfig}
                  setOffRampConfig={setOffRampConfig}
                  isOnRampSelected={isOnRampSelected}
                  isOffRampSelected={isOffRampSelected}
                  setIsOnRampSelected={setIsOnRampSelected}
                  setIsOffRampSelected={setIsOffRampSelected}
                />
              )}
              {!isOtcEnabled && <AddEntity text='Add another business entity' showUI />}

              {!isOtcEnabled && <div className={`tw-border-t tw-border-dashed tw-h-0 tw-my-4`} />}
              <span className={`f-16-400 tw-text-TEXT_SECONDARY`}>Learn more about our product offerings</span>
              <div className={`tw-flex tw-gap-4 tw-flex-col`}>
                {isOtcEnabled
                  ? productInformationDetailKeysV2?.map((eachOfferedProduct) => {
                      return (
                        <IntentProductDetails
                          key={eachOfferedProduct}
                          productV2={eachOfferedProduct as PRODUCT_TYPES_V2}
                        />
                      );
                    })
                  : productInformationDetailKeys?.map((eachOfferedProduct) => {
                      return (
                        <IntentProductDetails
                          product={eachOfferedProduct as PRODUCT_TYPES}
                          offeredProducts={companyDetails?.offered_products}
                          key={eachOfferedProduct}
                        />
                      );
                    })}
              </div>
              {!isOtcEnabled && (
                <div className={`f-12-300 tw-text-TEXT_SECONDARY`}>
                  Note:
                  <ul className='tw-list-disc tw-pl-3 tw-pt-2'>
                    <li>
                      If you select Debit cards, Business banking will be auto-selected to ensure a seamless application
                      process.
                    </li>
                    <li>
                      Digital asset account services will be provided through our Polish entity, i.e., Varni Labs Spółka
                      Z O
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div className='tw-fixed tw-bottom-0 tw-right-0 tw-p-4 tw-bg-BASE_SECONDARY tw-w-screen tw-z-10'>
              <div className={`tw-flex tw-justify-end ${layoutWidthClass}`}>
                <Button
                  buttonProps={{
                    btnType: BUTTON_TYPES.PRIMARY,
                    size: BUTTON_SIZE_TYPES.MEDIUM,
                    id: 'INTENT_SUBMIT_BUTTON',
                    eventCallback: trackMixpanel,
                    onClick: handleSubmit,
                    isLoading: isLoading,
                    disabled: !isIntentSelected,
                  }}
                >
                  Continue
                </Button>
              </div>
            </div>
          </div>
        </CommonWrapper>
      </div>
    </AccessWrapper>
  );
};

export default IntentPage;
